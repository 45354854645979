import React from 'react'
import PropTypes from 'prop-types'
import WorkList from './WorkList'

import picIch from '../images/ich.jpg'
import hockeyPic from '../images/partner.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Über mich</h2>
          <span className="image main"><img src={picIch} alt="Dominik Sander" /></span>
          <p>Ich, Jahrgang 1984, bin ein Full-Stack Web-Entwickler mit kreativer Ader. Bereits seit mitte der 90er Jahre bin ich leidenschaftlich am Programmieren und bereits 1998 ging meine aller erste selbst erstellte Webseite online.</p>
					<p>Angefangen bei statischen HTML-Seite mit Framesets, über simple PHP-Skripte, Content-Management-Systeme aller Arten, bis hin zu den neuen Technologien und JavaScript-Frameworks verfüge ich über sehr viel Erfahrung in fast allen Bereichen des Web.</p>
					<p>Komplexe Webseiten auf Basis des CMS Contao gehören ebenso zu meinem Portfolio, wie einfache Wordpress-Blogs aber auch moderne ReactJS-App mit Headless CMS Anbindung sind aus meiner täglichen Arbeit nicht mehr wegzudenken.</p>
					<hr/>
					<p>I am, born in 1984,  a full stack web developer with a creative vein. I started programming already in the nineties and in 1998 my very first self created website was online.</p>
					<p>Started with simple HTML pages with frameset, to simple PHP scripts, many different content management systems, and now to the newest technolgies and javascript frameworks, I gained experience in roughly every area of the world wide web.</p>
					<p>Complex website based on the CMS contao are part of my portfolio, and I've also created simple Wordpress blogs. But also modern react apps with a headless CMS are integral parts of my daily work.</p>
          {close}
        </article>

        <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Work</h2>
					<WorkList work={this.props.work} />
          {close}
        </article>

				<article id="impressum" className={`${this.props.article === 'impressum' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Impressum</h2>
					<h5>Angaben gemäß § 5 TMG</h5>
					<p>Dominik Sander<br/>
					Gröninger Weg 8<br/>
					74321 Bietigheim-Bissingen<br/>
					<a href="mailto:sander@dominix-design.de">sander@dominix-design.de</a></p>
					<h5>Design</h5>
					<p>Gatsby Starter - Dimension by <a href="https://html5up.net">HTML5 UP</a></p>
					<h5>Haftung für Inhalte</h5>
					<p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>
					<p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei bekannt werden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
					<h5>Haftung für Links</h5>
					<p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei bekannt werden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
					<h5>Urheberrecht</h5>
					<p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
					<p>Die Betreiber der Seiten sind bemüht, stets die Urheberrechte anderer zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.</p>
					<h5>Datenschutz</h5>
					<p>Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies soweit möglich stets auf freiwilliger Basis. Die Nutzung der Angebote und Dienste ist, soweit möglich, stets ohne Angabe personenbezogener Daten möglich.</p>
					<p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
					<p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
          {close}
        </article>

        <article id="partner" className={`${this.props.article === 'partner' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Partner</h2>
          <span className="image main"><img src={hockeyPic} alt="Partner" /></span>
          <p>Gute Partner sind unerlässlich für Erfolg. Ich habe über Jahre mehrere starke Partnerschaften aufgebaut von denen beide Seiten hervorragend profitieren.</p>
					<h3>Meine Partner</h3>
					<ul>
						<li>Freier Grafikdesigner <a href="http://manuelort.de">Manuel Ort</a></li>
						<li><a href="https://medienmann.de/">Der Medienmann</a> Spezialist für Online-Shop Lösungen</li>
						<li><a href="https://www.ilumy.de/">Ilumy Design</a> - Werbeagentur Kaufbeuren</li>
					</ul>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Kontakt</h2>
					<p>Der einfachste und schnellste Kontakt ist eine E-Mail: <a href="mailto:sander@dominix-design.de">sander@dominix-design.de</a>.<br/>Ich bin für Anfragen aller Art offen und versuche so schnell wie möglich auf jede E-Mail zu antworten.</p>
					<p>Eine weiter Kontaktmöglichkeit ist über diverse Social Media Kanäle:</p>
          <ul className="icons">
						<li><a href="https://github.com/dominixdesign/" className="icon fa-github" title="GitHub"><span className="label">GitHub</span></a></li>
            <li><a href="https://twitter.com/sanderdome" className="icon fa-twitter" title="Twitter"><span className="label">Twitter</span></a></li>
            <li><a href="https://www.facebook.com/dominik.sander" className="icon fa-facebook" title="Facebook"><span className="label">Facebook</span></a></li>
            <li><a href="https://www.instagram.com/sanderdome/" className="icon fa-instagram" title="Instagram"><span className="label">Instagram</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
