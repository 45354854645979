import React from 'react'
import WorkItem from './WorkItem'

class WorkList extends React.Component {
  render() {
		return <ul className="worklist">
		{this.props.work.map((document, key) => <WorkItem key={key} work={document} />)}
		</ul>
	}
}

export default WorkList
