import React from 'react'

class WorkItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hover: false}
  }
  render() {
		const {work} = this.props
		var tags = work.node.tags.split(',')
    var colors = []
    tags.map((tag, key) => {
      if(tag[0] === '#') {
        colors.push(tag)
        delete tags[key]
      }
    })
    var style = null,
      styleHover = null
    if(colors.length === 2) {
      style = {
        backgroundImage: 'linear-gradient(-135deg, transparent 6pt, '+colors[1]+' 6pt, '+colors[1]+' 14pt, '+colors[0]+' 14pt, '+colors[0]+' 21pt, transparent 21pt, transparent)'
      }
      styleHover = {
        backgroundImage: 'linear-gradient(-135deg, transparent 6pt, '+colors[1]+' 6pt, '+colors[1]+' 14pt, '+colors[0]+' 14pt, '+colors[0]+' 21pt, transparent 21pt, transparent)'
      }
    }

		return <li className="workitem" key={work.node.id} onMouseEnter={() => this.setState({hover:true})} onMouseLeave={() => this.setState({hover:false})}>
				<div className="workdata" style={this.state.hover ? styleHover : style}>
					<h2>{work.node.title} {tags.map((tag,key) => <i className={"fa fa-"+tag} key={key} />)}</h2>
					<p>{work.node.description}</p>
					 {work.node.link && <a href={work.node.link}>anschauen <i className="fa fa-external-link" /></a>}
				</div>
			</li>
	}
}

export default WorkItem
