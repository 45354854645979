import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; Dominik Sander. <a href="javascript:;" onClick={() => {props.onOpenArticle('impressum')}}>Impressum</a></p>
    </footer>
)

Footer.propTypes = {
		onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Footer
